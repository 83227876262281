import React from 'react';
import $ from 'jquery';

import Layout from '../components/layout';

import ProdecSlide from './prodec_slide.png'
import LinomatSlide from './linomat_slide.jpeg'

import './index.css'

interface Props {

}

interface State {

}

class Index extends React.Component<Props, State>  {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    $('.carousel').carousel({
      interval: 4000
    })
  }

  render() {
    return (
      <Layout>
        <div className="container-fluid">
          <div className="container">
            <div id="carouselExampleIndicators" className="carousel slide">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              </ol>

              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={ProdecSlide} className="d-block w-100" alt="Prodec professional tools" />
                </div>

                <div className="carousel-item">
                  <img src={LinomatSlide} className="d-block w-100" alt="Linomat edging rollers" />
                </div>
              </div>

              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>

              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Index;
